<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form @finish="handleSubmit" :model="formState" layout="vertical">
        <sdHeading as="h3"> Reset Password </sdHeading>
        <a-form-item name="username" label="Email Address">
          <a-input type="email" v-model:value="formState.email" />
        </a-form-item>
        <a-form-item name="password" initialValue="123456" label="Password">
          <a-input type="password" v-model:value="formState.password" placeholder="Password" />
        </a-form-item>
        <a-form-item name="confirm_password" initialValue="123456" label="Confirm Password">
          <a-input type="password" v-model:value="formState.confirm_password" placeholder="Confirm Password" />
        </a-form-item>
        <a-form-item>
          <sdButton class="btn-signin" htmlType="submit" type="primary" size="large">
            {{ loading ? 'Loading...' : 'Submit' }}
          </sdButton>
        </a-form-item>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { reactive, ref } from 'vue';
import { AuthWrapper } from './style';
import { DataService } from '@/config/dataService/dataService';
import { useRoute, useRouter } from 'vue-router';
import { Notification } from 'ant-design-vue';

const SignIn = {
  name: 'ResetPassword',
  components: { AuthWrapper },
  setup() {
    const loading = ref(false);
    const checked = ref(null);
    const { params } = useRoute();
    const { push } = useRouter();

    const handleSubmit = async () => {
      const {
        data: { status },
      } = await DataService.post(`auth/reset-password/${params.token}`, formState);

      if (status === 'success') {
        Notification['success']({
          message: 'Your password was updated successfully, You can login now.',
          duration: 10,
        });
        push({ name: 'login' });
      }
      this.loading = false;
    };

    const formState = reactive({
      email: '',
      password: '',
      confirm_password: '',
    });

    return {
      loading,
      checked,
      handleSubmit,
      formState,
    };
  },
};

export default SignIn;
</script>
