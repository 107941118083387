<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form @finish="handleSubmit" :model="formState" layout="vertical">
        <sdHeading as="h3"> 2FA Code </sdHeading>
        <div
          data-show="true"
          class="ant-alert ant-alert-info ant-alert-with-description ant-alert-no-icon sc-dFdIVH knYdtH"
          role="alert"
          style="margin-bottom: 30px"
        >
          <div class="ant-alert-content">
            <div class="ant-alert-description">
              Please check your email for the one time passcode. This will be valid for 10 minutes. If you do not see
              this email please check your Junk Folder
            </div>
          </div>
        </div>
        <a-form-item name="code" label="One time passcode">
          <a-input type="text" v-model:value="formState.passcode" />
        </a-form-item>
        <a-form-item>
          <sdButton class="btn-signin" htmlType="submit" type="primary" size="large" :disabled="isLoading">
            {{ isLoading ? 'Loading...' : 'Sign In' }}
          </sdButton>
        </a-form-item>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons-vue';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import { AuthWrapper } from './style';
import { useRoute } from 'vue-router';

const SignIn = {
  name: 'SignIn',
  components: { FacebookOutlined, TwitterOutlined, AuthWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const isLoading = computed(() => state.auth.loading);
    const { params } = useRoute();

    const handleSubmit = async () => {
      await dispatch('loginWithPasscode', { id: params.id, passcode: formState.passcode });
    };

    const formState = reactive({
      passcode: '',
    });

    return {
      isLoading,
      handleSubmit,
      formState,
    };
  },
};

export default SignIn;
</script>
